<template>
  <div class="box">
    <div class="boxWrap">
      <header class="headerCss">
        <div class="logoCss">
          <img
            class="logoImg"
            src="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/logo_416010126844182596.jpeg"
            alt=""
          />
        </div>
        <div class="contentBox">
          <h3 class="contentTitle">关于我们</h3>
          <p class="contentText">“成都众兴未来科技有限公司” 成立于2020年，是一家专注于殡葬行业的服务性公司</p>
          <p class="contentText">公司主要业务为顾客提供殡仪咨询、殡仪活动、选墓咨询等服务，公司拥有完整的咨询、策划和服务团队，以丰富行业经验为顾客提供高质量服务。</p>
        </div>
      </header>
      <div class="footerBox">
        <div class="title">联系我们</div>
        <div class="textBox">
          <div class="textCss">公司：成都众兴未来科技有限公司</div>
          <div class="textCss">地址：四川省成都市武侯区共和路8号附3号1层</div>
          <div class="textCss">
            邮箱：<a href="mailto:liuruiyang@zhxweilai.com"
              >liuruiyang@zhxweilai.com</a
            >
          </div>
           <div class="textCss">
            电话：<a href="tel:18811311641"
              >18811311641</a
            >
          </div>
        </div>

        <div class="beianbox">
          <a class="beian" href="http://beian.miit.gov.cn/"
            >蜀ICP备2021003234号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  destroyed() {},
  deactivated() {},
};
</script>
<style lang="scss" scoped>
.box {
  min-height: 100vh;
  box-sizing: border-box;
  background: #fff;
  .boxWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    box-sizing: border-box;
    padding-bottom: env(safe-area-inset-bottom);
  }
  .headerCss {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;
    flex-direction: column;
    .logoCss {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0.1rem 0.25rem 0.08rem rgba(0, 0, 0, 0.08);
      .logoImg {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
      }
    }
    .contentBox {
      margin-top: 0.5rem;
      border-radius: 0.12rem;
      width: 6.8rem;
      padding: 0.3rem;
      .contentTitle {
        font-size: 0.5rem;
        color: #333;
        text-align: center;
        font-weight: bold;
      }
      .contentText {
        font-size: .3rem;
        line-height: .5rem;
        color: #666;
        padding-top: .2rem;
        text-indent: 2em;
      }

    }
  }
  .footerBox {
    background-color: #333;
    padding: 0.2rem 0 0;
    .title {
      font-size: 0.5rem;
      color: #99a1ae;
      text-align: center;
      font-weight: bold;
      padding: 0.2rem 0 0.3rem;
    }
    .textBox {
      padding: 0 0.4rem;
    }
    .textCss {
      font-size: 0.3rem;
      line-height: 0.7rem;
      color: #a4acba;
    }
    .beianbox {
      margin-top: 0.2rem;
      background: #222;
      height: 0.9rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .beian {
      color: #5c6472;
    }
  }
}
</style>
